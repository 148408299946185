import clsx from 'clsx';
import { type FC, type ReactNode } from 'react';

export interface BadgeProps {
  className?: string;
  label: ReactNode;
  icon?: ReactNode;
}

export const Badge: FC<BadgeProps> = ({ className, icon, label = 'badge' }) => {
  return (
    <div
      className={clsx(
        'inline-flex items-center gap-2.5 rounded-full whitespace-nowrap bg-gray-100 p-1 pr-2.5 max-w-fit',
        className,
      )}
    >
      {icon ? icon : null}
      <span className="text-sm leading-6 tracking-wide whitespace-nowrap font-semibold text-primary-900">{label}</span>
    </div>
  );
};
